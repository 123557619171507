import React from 'react';

import "./Footer.css"

function Footer() {
  return (
   <footer className="footer">
     <p>© 2024 Todos os direitos reservados.</p>
    </footer>
  );
}

export default Footer;
