import React from 'react';

import "./Languages.css"

function Languages() {
  return (
    <section className="language-card">
      <h3>Idiomas</h3>
      <p>I<strong>nglês:</strong> Nível Intermediário</p>
    </section>
  );
}

export default Languages;
